<template>
  <div class="member-detail-page">
    <div class="member-info">
      <div class="box">用户</div>
      <div class="ws_pt20">
        <p class="ws_mb16">用户账号 ：{{ mobile }}</p>
        <p>
          用户等级 ：{{
            USER_LEVEL_TYPE.USER_LEVEL_VIP === vipCode ? '会员' : '普通'
          }}
        </p>
      </div>
    </div>
    <div class="ws_table">
      <div class="ws_operation ws_mb30">
        <p class="total">已绑定设备：{{ total }} 个</p>
        <el-button @click="fetchList" plain>刷新</el-button>
      </div>
      <el-table
        class="table"
        :data="tableData"
        v-loading="tableLoading"
        @sort-change="sortChange"
      >
        <el-table-column
          v-for="(item, index) in col"
          :key="index"
          v-bind="{
            ...item,
          }"
          show-overflow-tooltip
        />
        <el-table-column
          label="套餐到期时间"
          width="190"
          #default="scope"
          sortable="custom"
          prop="activeStartTime"
        >
          <span>{{ getTime(scope.row.activeStartTime) }}</span>
        </el-table-column>
        <el-table-column
          label="激活到期时间"
          width="190"
          #default="scope"
          sortable="custom"
          prop="activeEndTime"
        >
          <span :class="{ 'red-text': isEndTime(scope.row.activeEndTime) }">{{
            getTime(scope.row.activeEndTime)
          }}</span>
        </el-table-column>
        <el-table-column label="设备激活状态" width="160" #default="scope">
          <el-tag :type="statusTagMap[scope.row.deviceActiveStatus]">{{
            statusTextMap[scope.row.deviceActiveStatus]
          }}</el-tag>
        </el-table-column>
        <template #empty>
          <el-empty description="暂无数据" />
        </template>
      </el-table>
      <el-pagination
        class="ws_mt50"
        v-model:current-page="pageParams.curPage"
        v-model:page-size="pageParams.pageSize"
        :page-sizes="PAGE_SIZES"
        layout="->, total, sizes, prev, pager, next, jumper "
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>

  <ProductForm />
</template>
<script setup>
import { onMounted, ref, watch, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { queryMemberDetail } from '@/api'
import {
  CODE_ENUM,
  PAGE_SIZES,
  DEVICE_STATUS_CODE,
  USER_LEVEL_TYPE,
} from '@/common'
import moment from 'moment'

const statusTextMap = {
  [DEVICE_STATUS_CODE.NO_ACTIVATE]: '待激活',
  [DEVICE_STATUS_CODE.ACTIVATED]: '已激活',
}

const statusTagMap = {
  [DEVICE_STATUS_CODE.NO_ACTIVATE]: 'info',
  [DEVICE_STATUS_CODE.ACTIVATED]: 'primary',
}

const $route = useRoute()

const params = $route.query

const userId = params.userId
const mobile = params.mobile
const vipCode = params.vipCode

const tableData = ref([])

const pageParams = ref({
  curPage: 1,
  pageSize: 10,
})
const total = ref(0)

const handleSizeChange = () => {
  pageParams.value.curPage = 1
  fetchList()
}
const handleCurrentChange = () => {
  fetchList()
}

const col = [
  {
    prop: 'deviceName',
    label: '设备名称',
  },
  {
    prop: 'deviceSn',
    label: '设备SN',
  },
  {
    prop: 'imei',
    label: '设备IMEI',
  },
  {
    prop: 'productTypeValue',
    label: '产品名称',
  },
  {
    prop: 'productModel',
    label: '型号',
  },

  {
    prop: 'packageName',
    label: '套餐',
    formatter: ({ packageName }) => {
      return packageName || '-'
    },
  },
  // {
  //   prop: 'packageEndTime',
  //   label: '套餐到期时间',
  //   formatter: ({ packageEndTime }) => {
  //     return '<span style="color:red">aaa</span>'
  //     // return packageEndTime
  //     //   ? moment(packageEndTime).format('YYYY-MM-DD HH:mm:ss')
  //     //   : '-';
  //   }
  // }
]

const getTime = (packageEndTime) => {
  return packageEndTime
    ? moment(packageEndTime).format('YYYY-MM-DD HH:mm:ss')
    : '-'
}

const isEndTime = (packageEndTime) => {
  const nowTime = moment().valueOf()
  return nowTime > packageEndTime
}

const sortParams = ref({})
const sortChange = ({ prop, order }) => {
  sortParams.value = {
    prop,
    order,
  }
  fetchList()
}

const getSortKey = () => {
  const { order, prop } = sortParams.value || {}
  if (order) {
    const key = order === 'ascending' ? 'asc' : 'desc'
    return {
      [key]: prop,
    }
  }
}

const tableLoading = ref(false)
const fetchList = async () => {
  tableLoading.value = true
  const res = await queryMemberDetail({
    ...pageParams.value,
    userId,
    ...getSortKey(),
  }).finally(() => {
    tableLoading.value = false
  })
  if (res?.code === CODE_ENUM.SUCCESS) {
    tableData.value = res?.data?.records || []
    total.value = res?.data?.total || 0
  }
}

fetchList()
</script>
<style lang="scss" scoped src="./index.scss"></style>
